import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import backgroundImg from "../assets/login_bg.jpg";
import logo from "../assets/Logo.svg";
import ResetPasswordForm from "../Components/ResetPasswordForm";

const UsersResetPassword = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `linear-gradient(180deg, rgba(245, 246, 252, 0.4) 40%, rgba(32,108,94,0.8) 100%), url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* LOGO */}
      <Box
        sx={{
          position: "absolute",
          top: 50,
          left: { xs: "50%", sm: 50 },
          transform: { xs: "translateX(-50%)", sm: "none" },
        }}
      >
        <img src={logo} alt="" width="150px" />
      </Box>

      {/* FORM */}
      <Container maxWidth="xs">
        <ResetPasswordForm />
      </Container>
    </Box>
  );
};

export default UsersResetPassword;
