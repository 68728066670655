import { useState } from "react";
import { Box, Button, Typography, Modal, Stack } from "@mui/material/";
import LoadingButton from "@mui/lab/LoadingButton";
import { ALERT_TYPES } from "./Alert";

const DeleteModal = ({
  open,
  handleClose,
  refresh,
  idList,
  url,
  removePreview,
  setAlertMessage,
  setAlertType,
  setOpenAlert,
  item,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    idList?.forEach(async (id, index) => {
      try {
        const response = await fetch(`${url}/${id}`, {
          method: "DELETE",
          type: "cors",
          credentials: "include",
        });

        if (response.status === 200) {
          if (removePreview) {
            removePreview(null, null);
          }
          if (item) {
            let text = "";
            switch (item) {
              case "Kunde":
                text = idList.length > 1 ? "Kunder" : "Kunde";
                break;
              case "Admin":
                text = "Admin";
                break;
              case "Reise":
                text = idList.length > 1 ? "Reiser" : "Reise";
                break;
              default:
                return;
            }
            setAlertMessage(`${text} er slettet!`);
            setAlertType(ALERT_TYPES.SUCCESS);
            setOpenAlert(true);
          }
          if (idList.length - 1 === index) {
            refresh();
            setIsLoading(false);
            handleClose();
          }
        } else {
          setIsLoading(false);
          setAlertMessage("Oops, kunne ikke slette.");
          setAlertType(ALERT_TYPES.ERROR);
          setOpenAlert(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
        setAlertMessage("Oops, noe gikk galt! 😰");
        setAlertType(ALERT_TYPES.ERROR);
        setOpenAlert(true);
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        handleClose(reason, isLoading);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      position="relative"
    >
      <Box
        sx={{
          position: "absolute",
          width: { xs: "90%", sm: "auto" },
          top: "50%",
          left: "50%",
          background: "white",
          transform: "translate(-50%, -50%)",
          px: 4,
          py: 2,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            px: { xs: 1, sm: 4 },
            py: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Text */}

          <Typography
            variant="h4"
            sx={{
              textTransform: "none",
              fontSize: 16,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            Er du sikker på at du vil slette?
          </Typography>

          {/* Buttons - Cancel & Confirm  */}
          <Stack direction="row" alignItems="center" spacing={8} pt={3}>
            <Button disabled={isLoading} onClick={handleClose}>
              Avbryt
            </Button>
            <LoadingButton
              disableElevation
              loading={isLoading}
              variant="contained"
              onClick={handleDelete}
            >
              Bekreft
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
