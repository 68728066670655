import React, { useState } from "react";
import ContentContainer from "../Components/ContentContainer";
import { Typography, TextField, Grid, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert, { ALERT_TYPES } from "../Components/Alert";

import { useLocation, useNavigate } from "react-router";

const NewCustomer = ({ refreshList }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [customer, setCustomer] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zipCode: "",
  });
  const [isError, setIsError] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    phoneError: "",
  });

  const customerHandler = (e) => {
    const { value, name } = e.currentTarget;
    setCustomer((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const closeCustomer = () => {
    const pathname = location.pathname;
    const lastSlash = pathname.lastIndexOf("/");
    navigate(pathname.substr(0, lastSlash));
  };

  const createHandler = async () => {
    if (customer.firstName === "") {
      setIsError((prevState) => {
        return { ...prevState, firstNameError: "Fornavn kan ikke være tom" };
      });
    }
    if (customer.lastName === "") {
      setIsError((prevState) => {
        return { ...prevState, lastNameError: "Etternavn kan ikke være tom" };
      });
    }

    if (customer.email === "") {
      setIsError((prevState) => {
        return { ...prevState, emailError: "E-postadresse kan ikke være tom" };
      });
    }
    if (customer.phone === "") {
      setIsError((prevState) => {
        return { ...prevState, phoneError: "Telefonnummer kan ikke være tom" };
      });
    }

    if (
      customer.firstName &&
      customer.lastName &&
      customer.email &&
      customer.phone
    ) {
      setIsLoading(true);
      try {
        const response = await fetch(process.env.REACT_APP_REGISTER_USER, {
          method: "POST",
          type: "cors",
          credentials: "include",
          body: JSON.stringify(customer),
          headers: {
            "Content-Type": "application/json",
          },
        });
        setIsLoading(false);

        if (response.status === 201) {
          refreshList();
          closeCustomer();
        } else if (response.status === 409) {
          setAlertMessage("Denne e-postadressen finnes allerede i systemet!");
          setOpenAlert(true);
        } else {
          setAlertMessage("Oops, kunne ikke opprette adminbruker.");
          setOpenAlert(true);
        }
      } catch (error) {
        setIsLoading(false);
        setAlertMessage("Oops, noe gikk galt! 😰");
        setOpenAlert(true);
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={ALERT_TYPES.ERROR}
        duration={2000}
        message={alertMessage}
      />
      <ContentContainer title={"Opprett ny kunde"}>
        <Box
          sx={{
            width: { xs: "100%", lg: "60%" },
            px: 2,
            mt: { xs: 0, sm: 8, md: 6 },
          }}
        >
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 3, md: 4 }}
            columnSpacing={6}
            py={{ xs: 2, sm: 0 }}
          >
            {/* firstname */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h4">Fornavn</Typography>
              <TextField
                error={!!isError.firstNameError}
                helperText={isError.firstNameError}
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, firstNameError: "" };
                  })
                }
                name="firstName"
                placeholder="Fornavn"
                onChange={customerHandler}
                value={customer.firstName}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* middlename */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h4">Mellomnavn</Typography>
              <TextField
                name="middleName"
                placeholder="Mellomnavn"
                onChange={customerHandler}
                value={customer.middleName}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* lastname */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h4">Etternavn</Typography>
              <TextField
                error={!!isError.lastNameError}
                helperText={isError.lastNameError}
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, lastNameError: "" };
                  })
                }
                name="lastName"
                placeholder="Etternavn"
                onChange={customerHandler}
                value={customer.lastName}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12}>
              <Typography variant="h4">Adresse</Typography>
              <TextField
                name="address"
                placeholder="Adresse"
                onChange={customerHandler}
                value={customer.address}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* zipCode */}
            <Grid item xs={6}>
              <Typography variant="h4">Postnummer</Typography>
              <TextField
                name="zipCode"
                placeholder="Postnummer"
                onChange={customerHandler}
                value={customer.zipCode}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* city */}
            <Grid item xs={6}>
              <Typography variant="h4">By</Typography>

              <TextField
                name="city"
                placeholder="By"
                onChange={customerHandler}
                value={customer.city}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* email */}
            <Grid item xs={6}>
              <Typography variant="h4" noWrap>
                E-postadresse
              </Typography>

              <TextField
                error={!!isError.emailError}
                helperText={isError.emailError}
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, emailError: "" };
                  })
                }
                name="email"
                placeholder="E-postadresse"
                onChange={customerHandler}
                value={customer.email}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* phone */}
            <Grid item xs={6}>
              <Typography variant="h4" noWrap>
                Telefonnummer
              </Typography>
              <TextField
                error={!!isError.phoneError}
                helperText={isError.phoneError}
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, phoneError: "" };
                  })
                }
                name="phone"
                placeholder="Telefonnummer"
                onChange={customerHandler}
                value={customer.phone}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* Create button */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              <LoadingButton
                variant="contained"
                loading={isLoading}
                sx={{
                  my: 6,
                  width: { xs: 200, md: 0 },
                  px: 6,
                }}
                onClick={createHandler}
              >
                Opprett
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </ContentContainer>
    </>
  );
};

export default NewCustomer;
