import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Toast = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={4}
      ref={ref}
      {...props}
      sx={{ position: "relative", top: "10vh" }}
    />
  );
});

export const ALERT_TYPES = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

const Alert = ({ state, setState, type, message, duration = 6000 }) => {
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState(false);
  };

  return (
    <Snackbar
      open={state}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Toast onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Toast>
    </Snackbar>
  );
};

export default Alert;
