import { useState, useEffect, useContext } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";

import {
  Stack,
  Paper,
  IconButton,
  Button,
  Box,
  Typography,
  InputBase,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

import CustomerTrips from "./CustomerTrips";
import DeleteModal from "../Components/DeleteModal";
import ProtectedRoute from "../Components/ProtectedRoute";
import Alert, { ALERT_TYPES } from "../Components/Alert";
import AuthContext from "../contexts/AuthContext";
import StatusTag from "../Components/StatusTag";
import useWindowDimensions from "../utilities/useWindowDimensions";
import NewCustomer from "./NewCustomer";
import SendInviteButton from "../Components/SendInviteButton";

const Customers = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  auth.authHandler();

  const [isRefresh, setIsRefresh] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState();

  const [customerList, setCustomerList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { width } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = (reason, modalLoading) => {
    if (reason === "backdropClick" && modalLoading) {
      return;
    }
    setDeleteModal(false);
  };

  const filterHandler = (e) => {
    const { value } = e.currentTarget;

    let newList = customerList.filter(
      (customer) =>
        customer.fullName.toLowerCase().includes(value.toLowerCase()) ||
        customer.email.toLowerCase().includes(value.toLowerCase()) ||
        customer.phone?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredList(newList);
  };

  const createHandler = () => navigate("ny-kunde");
  // const createHandlerr = () => navigate("rediger-kunde:customerId/*");
  // Helper function to create full name from first, middle and lastname
  const createFullName = (data) => {
    const newData = data?.map((customer) => {
      let middleName = customer?.middleName ?? "";
      let newCustomer = {
        ...customer,
        fullName:
          `${customer.firstName} ${middleName} ${customer.lastName}`.replace(
            /  +/g,
            " "
          ),
      };
      return newCustomer;
    });

    return newData;
  };

  // Get all users
  useEffect(() => {
    setIsRefresh(false);
    setIsLoading(true);
    try {
      fetch(process.env.REACT_APP_GET_ALL_USERS, {
        method: "GET",
        type: "cors",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            setAlertType(ALERT_TYPES.ERROR);
            setAlertMessage("Oops, kunne ikke hente kunder.");
            setOpenAlert(true);
            setIsLoading(false);
          }
        })
        .then((data) => {
          setCustomerList(createFullName(data));
          setFilteredList(createFullName(data));
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
      setAlertType(ALERT_TYPES.ERROR);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setOpenAlert(true);
      setIsLoading(false);
    }
  }, [isRefresh]);

  // Table headers
  const columns = () => {
    const desktop = [
      {
        field: "fullName",
        headerName: "Navn",
        flex: true,
      },
      {
        field: "email",
        headerName: "E-postadressse",
        flex: true,
      },
      {
        field: "phone",
        headerName: "Telefonnummer",
        flex: true,
      },
      {
        field: "verified",
        headerName: "Status",
        headerAlign: "center",
        renderCell: StatusTag,
        width: 160,
        sortable: false,
      },
      {
        field: "invited",
        headerName: "Invitasjon",
        headerAlign: "center",
        width: 250,
        renderCell: (params) => {
          return (
            <SendInviteButton
              params={params}
              refreshList={setRefresh}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setOpenAlert={setOpenAlert}
            />
          );
        },
        sortable: false,
      },
    ];
    const mobile = [
      {
        field: "verified",
        headerName: "Status",
        headerAlign: "center",
        renderCell: StatusTag,
        width: 70,
        sortable: false,
      },
      {
        field: "fullName",
        headerName: "Navn",
        width: 200,
      },
      {
        field: "email",
        headerName: "E-postadressse",
        width: 200,
      },
      {
        field: "phone",
        headerName: "Telefonnummer",
        width: 140,
      },
      {
        field: "invited",
        headerName: "Invitasjon",
        headerAlign: "center",
        width: 120,
        renderCell: (params) => {
          return (
            <SendInviteButton
              params={params}
              refreshList={setRefresh}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setOpenAlert={setOpenAlert}
            />
          );
        },
        sortable: false,
      },
    ];

    if (width > 1200) {
      return desktop;
    }
    return mobile;
  };

  const rowHandler = (id) => navigate(`${id}`);
  const setRefresh = () => setIsRefresh(true);
  const refreshList = () => {
    setRefresh();
    setAlertType(ALERT_TYPES.SUCCESS);
    setAlertMessage("En ny kunde er opprettet! 😍");
    setOpenAlert(true);
  };
  const refreshEditCustomerList = () => {
    setRefresh();
    setAlertType(ALERT_TYPES.SUCCESS);
    setAlertMessage("Endringer hos kunde er lagret!");
    setOpenAlert(true);
  };

  const refreshCustomers = () => {
    setRefresh();
  };

  const refreshEditCustomer = () => {
    refreshEditCustomerList();
    //setIsRefresh(true);
  };

  return (
    <>
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseDeleteModal}
        refresh={setRefresh}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
        setOpenAlert={setOpenAlert}
        item={"Kunde"}
        idList={selectedRows}
        url={process.env.REACT_APP_DELETE_USER}
      />
      <Box
        sx={{
          pt: { xs: "76px", md: 3 },
          pb: { xs: 0, sm: 3 },
          px: { xs: 4 },
          position: "absolute",
          top: 0,
          left: { xs: 0, md: 240 },
          height: "100vh",
          width: { xs: "100vw", md: "calc(100vw - 240px)" },
        }}
      >
        <Alert
          state={openAlert}
          setState={setOpenAlert}
          type={alertType}
          duration={2000}
          message={alertMessage}
        />
        <Box container sx={{ p: { xs: 0, sm: 2 } }}>
          {/* Header */}

          <Typography variant="h1">Kunder</Typography>

          {/* Desktop Button - New Customer */}
          <div style={{ display: "flex" }}>
            <Button
              sx={{
                display: { xs: "none", sm: "flex" },
                mt: { xs: 6, md: 12 },
              }}
              onClick={createHandler}
              variant="contained"
              endIcon={<AddIcon />}
            >
              Opprett ny kunde
            </Button>
            {/* <Button
              disabled={!(selectedRows.length === 1)}
              sx={{
                display: { xs: "none", sm: "flex" },
                mt: { xs: 6, md: 12 },
              }}
              style={{ marginLeft: "10px" }}
              onClick={createHandlerr}
              variant="contained"
              endIcon={<EditIcon />}
            >
              Rediger
            </Button> */}
          </div>

          <Stack spacing={2} sx={{ mt: { xs: 6, md: 12 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Delete Button */}
              <Button
                disabled={!(selectedRows.length > 0)}
                variant="contained"
                onClick={handleOpenDeleteModal}
                endIcon={<DeleteIcon />}
                sx={{ px: { xs: 3, sm: 2 } }}
              >
                Slett
              </Button>
              {/* Search bar */}
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", md: 600 },
                }}
              >
                <InputBase
                  autoComplete={"123"}
                  sx={{ ml: 2 }}
                  placeholder={
                    width > 900
                      ? "Søk på navn, e-post eller telefonnummer"
                      : "Navn, e-post, telefon"
                  }
                  inputProps={{ "aria-label": "search-bar" }}
                  onChange={filterHandler}
                  fullWidth={true}
                  height={36.5}
                />
                <IconButton type="submit" sx={{ mr: 0.5 }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>

            {/* Table of all trips for user */}
            <Paper sx={{ height: { xs: "60vh", sm: "55vh" } }}>
              <DataGrid
                rows={filteredList}
                columns={columns()}
                onRowClick={(row) => rowHandler(row.id)}
                onSelectionModelChange={(ids) => {
                  setSelectedRows(ids);
                }}
                loading={isLoading}
                checkboxSelection
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                rowsPerPageOptions={[]}
                hideFooterPagination
                hideFooter={width < 1200}
                localeText={{
                  footerRowSelected: (count) =>
                    count !== 1
                      ? `${count.toLocaleString()} rader valgt`
                      : `${count.toLocaleString()} rad valgt`,
                  noRowsLabel: "Ingen rader å vise",
                }}
              />
            </Paper>
          </Stack>
        </Box>

        {/* Mobile: Add button */}
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{ mt: 4, display: { xs: "flex", sm: "none" } }}
        >
          <Button
            onClick={createHandler}
            variant="contained"
            endIcon={<AddIcon />}
          >
            Opprett ny kunde
          </Button>
        </Stack>

        <Routes>
          <Route
            path="ny-kunde"
            element={
              <ProtectedRoute>
                <NewCustomer refreshList={refreshList} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":customerId/*"
            element={
              <ProtectedRoute>
                <CustomerTrips
                  refreshed={refreshCustomers}
                  refreshEditCustomer={refreshEditCustomer}
                />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
    </>
  );
};

export default Customers;
