import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  auth.authHandler();
  if (auth.isAuth === false) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
