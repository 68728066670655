import { useState, useContext } from "react";
import paradisLogo from "../assets/logo.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import MenuIcon from "@mui/icons-material/Menu";
import "./SideNav.css";
import { NavLink } from "react-router-dom";
import {
  Typography,
  Box,
  Drawer,
  IconButton,
  CssBaseline,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Button,
  CircularProgress,
} from "@mui/material";
import AuthContext from "../contexts/AuthContext";
import Alert, { ALERT_TYPES } from "./Alert";

const drawerWidth = 240;

const SideNav = () => {
  const auth = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutHandler = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_SIGN_OUT_ADMIN}`, {
        method: "GET",
        type: "cors",
        credentials: "include",
      });

      setIsLoading(false);
      if (response.status === 200) {
        auth.authHandler();
      } else {
        setAlertMessage("Oops, kunne ikke logge ut.");
        setOpenAlert(true);
      }
    } catch (error) {
      setIsLoading(false);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setOpenAlert(true);
      console.error("Error:", error);
    }
  };

  const drawer = (
    <div>
      <Toolbar>
        <img src={paradisLogo} alt="" />
      </Toolbar>

      <List>
        {["Adminbruker", "Kunder"].map((text, index) => (
          <NavLink
            key={index}
            activeclassname={"active"}
            to={`${text.toLowerCase()}`}
            onClick={handleDrawerToggle}
          >
            <ListItem sx={{ py: 2.5, color: "#7D654F" }} button>
              <ListItemIcon sx={{ color: "#7D654F" }}>
                {index === 0 && <GridViewRoundedIcon />}
                {index === 1 && <PeopleAltRoundedIcon />}
              </ListItemIcon>
              <Typography fontFamily="Playfair Display">{text}</Typography>
            </ListItem>
          </NavLink>
        ))}
      </List>
      {/* Logout button */}
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 20,
          p: 4,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!isLoading ? (
          <Button
            onClick={logoutHandler}
            variant="contained"
            color="secondary"
            size="medium"
            disableElevation
          >
            Logg ut
          </Button>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={ALERT_TYPES.ERROR}
        duration={2000}
        message={alertMessage}
      />
      <Box
        component="header"
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "56px",
          background: "#f4eeea",
          position: "fixed",
          zIndex: 10,
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            m: 1,
            borderRadius: 100,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h4"
          color="secondary"
          sx={{ fontWeight: 600, fontSize: 15 }}
        >
          Paradisreiser Admin
        </Typography>
      </Box>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        {/* mini drawer for mobile & tablet*/}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#F4EEEA",
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* normal drawer*/}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#F4EEEA",
              border: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default SideNav;
