import { Box, Stack, Typography } from "@mui/material";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
const RotateWarning = () => {
  return (
    <Box
      className={"warningScreen"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        background: "rgba(0,0,0,0.9)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 99999,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ height: "100vh", width: "100vw" }}
      >
        <ScreenRotationIcon
          className={"rotateWarning"}
          sx={{ fontSize: 50, color: "rgb(200,200,200)" }}
        />
        <Typography
          variant="h4"
          sx={{
            color: "rgb(200,200,200)",
            fontWeight: 700,
            fontSize: 16,
            letterSpacing: 1,
          }}
        >
          Appen fungerer best i portrett modus 😊
        </Typography>
      </Stack>
    </Box>
  );
};

export default RotateWarning;
