import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import AdminUsers from "./Pages/AdminUsers";
import Customers from "./Pages/Customers";
import SideNav from "./Components/SideNav";
import theme from "./themes/default";
import AdminLogIn from "./Pages/AdminLogIn";
import ProtectedRoute from "./Components/ProtectedRoute";
import RotateWarning from "./Components/RotateWarning";
import AdminResetPassword from "./Pages/AdminResetPassword";
import UsersResetPassword from "./Pages/UsersResetPassword";

const App = () => {
  const path = useLocation().pathname;

  return (
    <ThemeProvider theme={theme}>
      <RotateWarning />

      {!(
        path === "/" ||
        path === "/reset-password/admin" ||
        path === "/reset-password/user"
      ) && <SideNav />}

      <Routes>
        <Route path="/" element={<AdminLogIn />} />
        <Route path="/reset-password/admin" element={<AdminResetPassword />} />
        <Route path="/reset-password/user" element={<UsersResetPassword />} />
        <Route
          path="kunder/*"
          element={
            <ProtectedRoute>
              <Customers />
            </ProtectedRoute>
          }
        />
        <Route
          path="adminbruker/*"
          element={
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>
          }
        ></Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
