import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, Route, Routes } from "react-router-dom";

import { Paper, Button, Box, Skeleton, Typography, Stack } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import travelSvg from "../assets/undraw_travel.svg";

import ContentContainer from "../Components/ContentContainer";
import CreateTrip from "./CreateTrip";
import EditTrip from "./EditTrip";
import ProtectedRoute from "../Components/ProtectedRoute";
import DeleteModal from "../Components/DeleteModal";
import Alert, { ALERT_TYPES } from "../Components/Alert";

import useWindowDimensions from "../utilities/useWindowDimensions";
import EditCustomer from "./EditCustomer";
import CopyTripDialog from "../Components/CopyTripDialog";

function CustomerTrips({ refreshed, refreshEditCustomer }) {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState();
  const [isRefresh, setIsRefresh] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [tripList, setTripList] = useState([]);
  const [userInfo, setUserInfo] = useState({
    email: "",
    phone: "",
    addresse: "",
  });
  const [isTrips, setIsTrips] = useState(false);
  const { width } = useWindowDimensions();
  const [selectedRows, setSelectedRows] = useState([]);

  const createHandler = () => navigate("ny-reise");
  const editHandler = (id) => navigate(`${id}`);
  const editCustomerHandler = (id) => navigate(`rediger-kunde`);

  const setRefresh = () => setIsRefresh(true);
  const setTripName = useCallback((name) => setAlertMessage(name), []);

  const handleOpenDeleteModal = () => setDeleteModal(true);
  const handleCloseDeleteModal = (reason, modalLoading) => {
    if (reason === "backdropClick" && modalLoading) {
      return;
    }
    setDeleteModal(false);
  };

  const handleClickOpenDialog = () => setOpenDialog(true);

  const handleCloseDialog = () => setOpenDialog(false);

  const refreshList = () => {
    setRefresh();
    refreshed();
    setAlertType(ALERT_TYPES.SUCCESS);
    setOpenAlert(true);
    refreshed();
  };
  const refreshListEditCustomer = () => {
    refreshEditCustomer();
    setIsRefresh(true);
  };

  const newDates = (data) => {
    try {
      const convertDate = (date) => {
        let newDate = date.substr(0, 10).split("-").reverse().join("-");
        return newDate;
      };
      let newData = data.map((trip) => {
        return {
          ...trip,
          startDate: convertDate(trip.startDateUtc),
          endDate: convertDate(trip.endDateUtc),
          editDate: convertDate(trip.editedAtUtc),
        };
      });
      return newData;
    } catch (error) {}
  };

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_GET_ALL_USERS, {
        method: "GET",
        type: "cors",
        credentials: "include",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          let matchedUser = data.filter(
            (user) => user.id.toString() === customerId
          );
          let middleName = matchedUser[0]?.middleName ?? "";
          setUsername(
            `${matchedUser[0].firstName} ${middleName} ${matchedUser[0].lastName}`.replace(
              /  +/g,
              " "
            )
          );
          setUserInfo({
            email: matchedUser[0].email,
            phone: matchedUser[0].phone,
            addresse: matchedUser[0].fullAddress,
          });
        });
    } catch (error) {
      console.error("Error:", error);
    }

    // GET TRIPS
    try {
      fetch(`${process.env.REACT_APP_GET_ALL_TRIPS}/${customerId}`, {
        method: "GET",
        type: "cors",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            setIsRefresh(false);
            setAlertType(ALERT_TYPES.ERROR);
            setAlertMessage("Oops, kunne ikke hente reiser.");
            setOpenAlert(true);
          }
        })
        .then((data) => {
          setIsRefresh(false);
          setTripList(newDates(data));
          data?.length === 0 ? setIsTrips(true) : setIsTrips(false);
        });
    } catch (error) {
      console.error("Error:", error);
      setIsRefresh(false);
      setAlertType(ALERT_TYPES.ERROR);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setOpenAlert(true);
    }
  }, [customerId, isRefresh]);

  // Table headers
  const columns = () => {
    const desktop = [
      {
        field: "name",
        headerName: "Navn",
        flex: true,
      },
      {
        field: "startDate",
        headerName: "Startdato",
        flex: true,
      },
      {
        field: "endDate",
        headerName: "Sluttdato",
        flex: true,
      },
      {
        field: "editDate",
        headerName: "Sist Endret",
        flex: true,
      },
    ];
    const mobile = [
      {
        field: "name",
        headerName: "Reisenavn",
        flex: width > 600 ? true : false,
        width: 150,
      },
      {
        field: "startDate",
        headerName: "Startdato",
        width: 120,
      },
      {
        field: "endDate",
        headerName: "Sluttdato",
        width: 120,
      },
      {
        field: "editDate",
        headerName: "Sist Endret",
        width: 120,
      },
    ];

    if (width > 1200) {
      return desktop;
    }
    return mobile;
  };

  return (
    <>
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseDeleteModal}
        refresh={setRefresh}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
        setOpenAlert={setOpenAlert}
        item={"Reise"}
        idList={selectedRows}
        url={process.env.REACT_APP_DELETE_TRIP}
      />
      <CopyTripDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        setRefresh={setRefresh}
        refresh={refreshed}
        item={"Reise"}
        tripIdList={selectedRows}
        customerId={customerId}
      />

      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={alertType}
        duration={2000}
        message={alertMessage}
      />

      <ContentContainer title={username}>
        <Box px={2}>
          {/* User Info */}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 10 }}
            sx={{ mt: { xs: 4, md: -2 }, mb: { xs: 4, md: 10 } }}
          >
            {width > 600 && (
              <>
                <Stack>
                  <Typography
                    variant="h4"
                    py={{ xs: 0, sm: 1 }}
                    color="#696969"
                  >
                    E-postadresse
                  </Typography>
                  <Typography>{userInfo.email}</Typography>
                  {isRefresh && <Skeleton width={170} />}
                </Stack>

                <Stack>
                  <Typography
                    variant="h4"
                    py={{ xs: 0, sm: 1 }}
                    color="#696969"
                  >
                    Telefonnummer
                  </Typography>
                  <Typography>{userInfo.phone}</Typography>
                  {isRefresh && <Skeleton />}
                </Stack>
              </>
            )}

            <Stack>
              <Typography variant="h4" py={{ xs: 0, sm: 1 }} color="#696969">
                Full adresse
              </Typography>
              <Typography>{userInfo.addresse}</Typography>
              {isRefresh && <Skeleton width={230} />}
            </Stack>
          </Stack>

          {/* Delete & Add buttons */}
          <Stack direction="row" spacing={width > 600 ? 2: 1} alignItems="center">
            {!isTrips && (
              <Button
                disabled={!(selectedRows.length > 0)}
                variant="contained"
                onClick={handleOpenDeleteModal}
                endIcon={<DeleteIcon />}
              >
                Slett
              </Button>
            )}
            <Button
              onClick={createHandler}
              variant="contained"
              endIcon={<AddIcon />}
            >
              {width > 600 ? "opprett ny reise" : "ny"}
            </Button>
            <Button
              onClick={editCustomerHandler}
              variant="contained"
              endIcon={<EditIcon />}
            >
              {width > 600 ? "Rediger kunde" : "endre"}
            </Button>
            {!isTrips && (
              <Button
                onClick={handleClickOpenDialog}
                variant="contained"
                endIcon={<ContentCopyIcon />}
                disabled={!(selectedRows.length == 1)}
              >
                {width > 650 ? "Kopier reise" : "Kopi"}
              </Button>
            )}
          </Stack>

          {/* Datagrid */}
          {!isTrips && (
            <Paper
              sx={{
                height: { xs: "60vh", sm: "55vh" },
                mt: 2,
              }}
            >
              <DataGrid
                rows={tripList}
                columns={columns()}
                onRowClick={(row) => editHandler(row.id)}
                onSelectionModelChange={(ids) => {
                  setSelectedRows(ids);
                }}
                loading={isRefresh}
                checkboxSelection
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                rowsPerPageOptions={[]}
                hideFooterPagination
                hideFooter={width < 1200}
                localeText={{
                  footerRowSelected: (count) =>
                    count !== 1
                      ? `${count.toLocaleString()} rader valgt`
                      : `${count.toLocaleString()} rad valgt`,
                  noRowsLabel: "",
                }}
              />
            </Paper>
          )}

          {/* No trips img & text */}
          {isTrips && (
            <>
              <img
                id="travelSvg"
                src={travelSvg}
                alt="travel_together"
                style={{ marginTop: 65 }}
              />
              <Typography
                variant="h1"
                sx={{
                  position: "absolute",
                  top: { xs: "55%", sm: "50%" },
                  left: "50%",
                  transform: "translateX(-50%)",
                  opacity: 0.5,
                  fontWeight: 500,
                  letterSpacing: 1,
                  whiteSpace: "nowrap",
                }}
              >
                {username?.split(" ")[0]} har ingen reiser
              </Typography>
            </>
          )}
        </Box>
      </ContentContainer>

      <Routes>
        <Route
          path=":tripId"
          element={
            <ProtectedRoute>
              <EditTrip
                customerId={customerId}
                refreshList={refreshList}
                alertTripName={setTripName}
                refreshLinks={() => setRefresh(true)}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`rediger-kunde`}
          element={
            <ProtectedRoute>
              <EditCustomer
                customerId={customerId}
                refreshEditCustomer={refreshListEditCustomer}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="ny-reise"
          element={
            <ProtectedRoute>
              <CreateTrip
                refreshList={refreshList}
                customerId={customerId}
                setTripName={setTripName}
                refreshLinks={() => setIsRefresh(true)}
              />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default CustomerTrips;
