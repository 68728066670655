import { useContext, useRef, useState } from "react";
import { Stack, TextField, Paper, Typography } from "@mui/material";
import AuthContext from "../contexts/AuthContext";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert, { ALERT_TYPES } from "./Alert";

const LoginForm = () => {
  const auth = useContext(AuthContext);

  const emailRef = useRef();
  const passwordRef = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({
    emailError: "",
    passwordError: "",
    resetEmailError: "",
  });
  const [forgot, setForgot] = useState(false);
  const [sendtResetEmail, setSendtResetEmail] = useState("");

  const [openAlert, setOpenAlert] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const keyDownHandler = (e) => e.key === "Enter" && logInHandler();
  const showForgot = () => setForgot(true);
  const hideForgot = () => setForgot(false);

  const logInHandler = async () => {
    const enteredEmail = emailRef.current.children[1].children[0].value;
    const enteredPassword = passwordRef.current.children[1].children[0].value;

    if (!enteredEmail)
      setIsError((prev) => {
        return { ...prev, emailError: "E-postadresse kan ikke være tom" };
      });

    if (!enteredPassword)
      setIsError((prev) => {
        return { ...prev, passwordError: "Passord kan ikke være tom" };
      });

    if (enteredEmail && enteredPassword) {
      setIsLoading(true);

      try {
        const response = await fetch(process.env.REACT_APP_SIGN_IN_ADMIN, {
          method: "POST",
          type: "cors",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: enteredEmail,
            password: enteredPassword,
          }),
        });
        setIsLoading(false);

        if (response.status === 200) {
          auth.authHandler();
        } else {
          setIsError((prev) => ({
            ...prev,
            emailError: "Opplysningene stemmer ikke",
            passwordError: "Opplysningene stemmer ikke",
          }));
        }
      } catch (error) {
        setIsLoading(false);
        setOpenAlert(true);
        console.error("Error:", error);
      }
    }
  };

  const handleResetPassword = async () => {
    const resetEmail = emailRef.current.children[1].children[0].value;

    if (!resetEmail) {
      setIsError((prev) => {
        return { ...prev, resetEmailError: "E-postadresse kan ikke være tom" };
      });
      return;
    }

    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_FORGOT_ADMIN_PASSWORD}/${resetEmail}`,
      {
        method: "GET",
        type: "cors",
        credentials: "include",
      }
    );
    setIsLoading(false);

    if (response.status === 200) {
      setSendtResetEmail(resetEmail);
    } else {
      setIsError((prev) => ({
        ...prev,
        resetEmailError: "Oops..., noe gikk galt",
      }));
    }
  };

  return (
    <Paper elevation={15} onKeyDown={keyDownHandler}>
      {/* Login */}
      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={ALERT_TYPES.ERROR}
        duration={2000}
        message={"Oops, noe gikk galt! 😰"}
      />
      {!forgot && (
        <Stack
          height={480}
          spacing={{ xs: 4, sm: 6 }}
          backgroundColor="white"
          py={6}
          px={5}
          borderRadius={2}
          elevation={2}
          justifyContent="center"
        >
          {/* Heading */}
          <Typography
            variant="h1"
            color="secondary.dark"
            sx={{
              textAlign: "center",
              fontWeight: "700",
            }}
          >
            Logg inn
          </Typography>

          {/* Inputs */}
          <Stack spacing={3}>
            {/* Input Email */}
            <TextField
              required
              onClick={() => {
                setIsError((prevState) => {
                  return { ...prevState, emailError: "" };
                });
              }}
              label="Email"
              ref={emailRef}
              variant="outlined"
              error={!!isError.emailError}
              helperText={isError.emailError}
            />

            {/* Input Password */}
            <TextField
              onClick={() =>
                setIsError((prevState) => {
                  return { ...prevState, passwordError: "" };
                })
              }
              error={!!isError.passwordError}
              helperText={isError.passwordError}
              required
              id="outlined-password"
              label="Passord"
              type={showPassword ? "text" : "password"}
              ref={passwordRef}
              autoComplete="current-password"
              fullWidth={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          {/* Buttons */}
          <Stack spacing={3}>
            {/* Login */}
            <LoadingButton
              loading={isLoading}
              onClick={logInHandler}
              variant="contained"
              color="secondary"
              sx={{ py: 1 }}
            >
              Logg Inn
            </LoadingButton>
            {/* Forgot password? */}
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontWeight: 600,
                textTransform: "none",
                p: 0,
                fontSize: 15,
                cursor: "pointer",
                color: "primary.light",
              }}
              onClick={showForgot}
            >
              Glemt passord?
            </Typography>
          </Stack>
        </Stack>
      )}

      {/* Forgot */}
      {forgot && !sendtResetEmail && (
        <Stack
          height={480}
          spacing={{ xs: 2, sm: 5 }}
          backgroundColor="white"
          py={6}
          px={5}
          borderRadius={2}
          elevation={2}
          justifyContent="center"
        >
          {/* Heading */}
          <Typography
            variant="h1"
            color="secondary.dark"
            sx={{
              textAlign: "center",
              fontWeight: "700",
            }}
          >
            Glemt passord
          </Typography>
          {/* Text */}
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: 18,
              fontWeight: 500,
              textTransform: "none",
              lineHeight: 1.5,
            }}
          >
            Fyll inn din epost og trykk på send
          </Typography>

          {/* Inputs */}
          <Stack spacing={3}>
            {/* Input Email */}
            <TextField
              required
              label="Email"
              ref={emailRef}
              variant="outlined"
              onClick={() => {
                setIsError((prevState) => {
                  return { ...prevState, resetEmailError: "" };
                });
              }}
              error={!!isError.resetEmailError}
              helperText={isError.resetEmailError}
            />
          </Stack>

          <Stack spacing={3}>
            {/* Button */}
            <LoadingButton
              loading={isLoading}
              onClick={() => handleResetPassword()}
              variant="contained"
              color="secondary"
              sx={{ py: 1 }}
            >
              Send
            </LoadingButton>
            {/* Back to login button */}
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontWeight: 600,
                textTransform: "none",
                p: 0,
                fontSize: 15,
                cursor: "pointer",
                color: "primary.light",
                pointerEvents: `${isLoading && "none"}`,
              }}
              onClick={hideForgot}
            >
              Tilbake til logg inn
            </Typography>
          </Stack>
        </Stack>
      )}

      {/* Sendt Email */}
      {!!sendtResetEmail && (
        <Stack
          spacing={{ xs: 0, sm: 2 }}
          backgroundColor="white"
          py={6}
          px={5}
          borderRadius={2}
          elevation={2}
        >
          {/* Heading */}
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: 18,
              fontWeight: 500,
              textTransform: "none",
              lineHeight: 1.5,
            }}
          >
            Vi har sendt en e-post til
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: "1.1rem",
              fontWeight: "600",
              letterSpacing: "1.1px",
              backgroundColor: "#f4eeea",
              p: 2,
              borderRadius: 1,
              textAlign: "center",
            }}
          >
            {sendtResetEmail}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: 18,
              fontWeight: 500,
              textTransform: "none",
              lineHeight: 1.5,
            }}
          >
            Vennligst sjekk innboksen din.
          </Typography>
        </Stack>
      )}
    </Paper>
  );
};

export default LoginForm;
