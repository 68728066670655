import { useState, useEffect } from "react";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useWindowDimensions from "../utilities/useWindowDimensions";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { InputBase, IconButton } from "@mui/material";
import Alert, { ALERT_TYPES } from "../Components/Alert";
import SearchIcon from "@mui/icons-material/Search";

const CopyTripDialog = ({
  openDialog,
  handleCloseDialog,
  chooseCustomer,
  setRefresh,
  tripIdList,
  item,
  customerId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [customerList, setCustomerList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tripInfo, setTripInfo] = useState({
    tripName: "",
    mapCoordinates: "",
  });
  const [image, setImage] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [theme, setTheme] = useState(true);
  const [tripName, setTripName] = useState("");
  const [infoList, setInfoList] = useState([]);

  const [isImgBar, setIsImgBar] = useState(false);
  const closeImgBar = () => setIsImgBar(false);

  const [fileList, setFileList] = useState([]);
  const [previewURL, setPreviewURL] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [nameError, setNameError] = useState("");
  const { width } = useWindowDimensions();
  const [imgList, setImgList] = useState(null);
  const [copiedTripName, setCopiedTripName] = useState("");

  const [copiedTripData, setCopiedTripData] = useState();

  var dayjs = require("dayjs");

  const createFullName = (data) => {
    const newData = data?.map((customer) => {
      let middleName = customer?.middleName ?? "";
      let newCustomer = {
        ...customer,
        fullName:
          `${customer.firstName} ${middleName} ${customer.lastName}`.replace(
            /  +/g,
            " "
          ),
      };
      return newCustomer;
    });

    return newData;
  };

  useEffect(() => {
    try {
      fetch(`${process.env.REACT_APP_GET_IMAGES}`, {
        method: "GET",
        type: "cors",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 404) {
            setImgList(null);
          } else {
            setAlertMessage("Oops, kunne ikke hente bilder.");
            setOpenAlert(true);
          }
        })
        .then((data) => {
          data !== null && setImgList(data);
        });
    } catch (error) {
      console.error("Error:", error);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setOpenAlert(true);
    }
  }, [selectedRows]);

  const filterHandler = (e) => {
    const { value } = e.currentTarget;

    let newList = customerList.filter(
      (customer) =>
        customer.fullName.toLowerCase().includes(value.toLowerCase()) ||
        customer.email.toLowerCase().includes(value.toLowerCase()) ||
        customer.phone?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredList(newList);
  };

  useEffect(() => {
    if (tripIdList.length != 0) {
      getTripInfo();
      getTripData();
    }
  }, [openDialog, selectedRows]);

  // Get all users
  useEffect(() => {
    setRefresh(false);
    setIsLoading(true);
    try {
      fetch(process.env.REACT_APP_GET_ALL_USERS, {
        method: "GET",
        type: "cors",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            setAlertType(ALERT_TYPES.ERROR);
            setAlertMessage("Oops, kunne ikke hente kunder.");
            setOpenAlert(true);
            setIsLoading(false);
          }
        })
        .then((data) => {
          setCustomerList(createFullName(data));
          setFilteredList(createFullName(data));
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
      setAlertType(ALERT_TYPES.ERROR);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setOpenAlert(true);
      setIsLoading(false);
    }
  }, []);

  // Table headers
  const columns = () => {
    const desktop = [
      {
        field: "fullName",
        headerName: "Navn",
        flex: true,
      },
      {
        field: "email",
        headerName: "E-postadressse",
        flex: true,
      },
      {
        field: "phone",
        headerName: "Telefonnummer",
        flex: true,
      },
    ];
    const mobile = [
      {
        field: "fullName",
        headerName: "Navn",
        width: 200,
      },
      {
        field: "email",
        headerName: "E-postadressse",
        width: 200,
      },
      {
        field: "phone",
        headerName: "Telefonnummer",
        width: 140,
      },
    ];

    if (width > 1200) {
      return desktop;
    }
    return mobile;
  };

  const addTripToCustomer = async () => {
    try {
      copyTripHandler();
    } catch (error) {
      console.log("Error", error);
    }
    setSelectedRows(0);
  };

  const getTripInfo = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_ONE_TRIP}/${tripIdList[0]}/${customerId}`,
      {
        method: "GET",
        type: "cors",
        credentials: "include",
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      setTripInfo((prevState) => ({
        ...prevState,
        tripName: data.name,
        mapCoordinates: `${data.mapCoordinates ?? ""}`,
      }));

      setDateRange([new Date(data.startDateUtc), new Date(data.endDateUtc)]);

      setTheme(data.theme);
    } else {
      setAlertType(ALERT_TYPES.ERROR);
      setAlertMessage("Oops, kunne ikke hente reisen.");
      setOpenAlert(true);
    }
  };

  //GET TRIP DATA
  const getTripData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_ONE_TRIP}/${tripIdList[0]}/${customerId}`,
      {
        method: "GET",
        type: "cors",
        credentials: "include",
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      setTripName(data.name);
      data.travelTips && setInfoList([...data.travelTips]);
      data.travelDocuments && setFileList([...data.travelDocuments]);
      setCopiedTripName(tripInfo.tripName);

      setCopiedTripData(data);

      const filtered = imgList.filter((employee) => {
        return employee.id == data.image?.id;
      });
      const filteredImage = filtered[0];

      setImage(filteredImage);
      setPreviewURL(data.image?.url);
    } else {
      setAlertType(ALERT_TYPES.ERROR);
      setAlertMessage("Oops, kunne ikke hente reisen.");
      setOpenAlert(true);
    }
  };

  const postTrip = async (imageId) => {
    const formData = new FormData();
    formData.append("name", tripInfo.tripName);
    formData.append(
      "startDateUtc",
      dayjs(dateRange[0]).format().replace(/-/g, "/").replace(/T.+/, "")
    );
    formData.append(
      "endDateUtc",
      dayjs(dateRange[1]).format().replace(/-/g, "/").replace(/T.+/, "")
    );

    let newList = infoList.map((info, index) => ({
      title: info.title,
      description: info.description,
      link: info.link,
      order: info.order,
    }));

    formData.append("travelTips", JSON.stringify(newList));

    formData.append("userId", selectedRows[0]);

    imageId && formData.append("imageId", imageId);
    let sortedList = [];
    if (fileList[0]?.order == 0 && fileList[1]?.order == 0) {
      sortedList = fileList.sort((a, b) => (a.order > b.order ? -1 : 1));
    } else {
      sortedList = fileList.sort((a, b) => (a.order > b.order ? 1 : -1));
      setFileList(sortedList);
    }

    try {
      for (var i = 0; i < sortedList.length; i++) {
        const savedFile = await fetch(
          `${process.env.REACT_APP_SAVE_FILE}/${sortedList[i].id}`,
          {
            method: "GET",
            type: "cors",
            credentials: "include",
          }
        );

        const response = await savedFile.blob();
        const myBlob = new Blob([response], {
          type: "application/pdf",
        });

        const myFile = new File([myBlob], sortedList[i].name, {
          type: response.type,
        });
        myFile.order = sortedList[i].order;

        formData.append("files", myFile);
      }
    } catch (error) {}

    formData.append("theme", theme);
    formData.append("MapCoordinates", tripInfo.mapCoordinates);

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_CREATE_TRIP}`, {
        method: "POST",
        type: "cors",
        credentials: "include",
        body: formData,
      });
      if (response.status === 201) {
        setRefresh();
        // closeTrip();
        setAlertMessage(`Reisen "${tripInfo.tripName}" er lagt til`);
        setAlertType(ALERT_TYPES.SUCCESS);
        setOpenAlert(true);
        setIsLoading(false);
      } else {
        setAlertMessage("Oops, kunne ikke opprette reisen.");
        setOpenAlert(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setAlertType(ALERT_TYPES.ERROR);
      setOpenAlert(true);
      setIsLoading(false);
    }
  };

  const postImage = async () => {
    if (image instanceof File) {
      let formImg = new FormData();
      formImg.append("image", image);
      try {
        let response = await fetch(process.env.REACT_APP_ADD_IMAGE, {
          method: "POST",
          credentials: "include",
          type: "cors",
          body: formImg,
        });
        if (response.status === 200) {
          setImage();
          return await response.json();
        } else {
          setAlertMessage("Oops, kunne ikke laste opp bildet.");

          setOpenAlert(true);
          return null;
        }
      } catch (error) {
        console.error("Error:", error);
        setAlertType(ALERT_TYPES.ERROR);
        setAlertMessage("Oops, noe gikk galt! 😰");
        setOpenAlert(true);
        return null;
      }
    }

    return null;
  };

  const copyTripHandler = async () => {
    if (image === null) {
      postTrip(null);
      return;
    }
    const dbImg = await postImage();
    if (!(image instanceof File) && dbImg === null) {
      postTrip(image?.id);
      return;
    }

    postTrip(dbImg.id);
  };

  const getTripName = () => {
    return tripInfo.tripName;
  };

  return (
    <>
      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={alertType}
        duration={2000}
        message={alertMessage}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { maxWidth: "revert" } }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Legg til " + getTripName()}
        </DialogTitle>
        <DialogContent>
          <Paper
            sx={{
              height: { xs: "60vh", sm: "55vh" },
              width: { xs: "120vh", sm: "100vh" },
            }}
          >
            <div style={{ display: "flex", marginBottom: 15 }}>
              <InputBase
                autoComplete={"123"}
                placeholder={
                  width > 900
                    ? "Søk på navn, e-post eller telefonnummer"
                    : "Navn, e-post, telefon"
                }
                inputProps={{ "aria-label": "search-bar" }}
                onChange={filterHandler}
                fullWidth={true}
                height={36.5}
              />
              <IconButton type="submit" sx={{ mr: 0.5 }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </div>
            <DataGrid
              rows={filteredList}
              columns={columns()}
              onRowClick={(row) => {}}
              onSelectionModelChange={(ids) => {
                setSelectedRows(ids);
              }}
              loading={isLoading}
              // checkboxSelection
              disableSelectionOnClick={false}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              rowsPerPageOptions={[]}
              hideFooterPagination
              hideFooter={width < 1200}
              localeText={{
                footerRowSelected: (count) =>
                  count !== 1
                    ? `${count.toLocaleString()} rader valgt`
                    : `${count.toLocaleString()} rad valgt`,
                noRowsLabel: "Ingen rader å vise",
              }}
            />
          </Paper>
        </DialogContent>
        <DialogActions sx={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              marginBottom: 15,
              flexDirection: "row",
              gap: 20,
            }}
          >
            <Button
              onClick={() => {
                handleCloseDialog();
                setSelectedRows(0);
              }}
            >
              Lukk
            </Button>
            <LoadingButton
              loading={isLoading}
              disabled={selectedRows.length == 1 ? false : true}
              variant="contained"
              sx={{
                marginRight: 2,
              }}
              onClick={addTripToCustomer}
            >
              Legg til
            </LoadingButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CopyTripDialog;
