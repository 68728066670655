import { useState, useEffect, useContext } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Button, Box, Typography, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import ProtectedRoute from "../Components/ProtectedRoute";
import Alert, { ALERT_TYPES } from "../Components/Alert";
import AuthContext from "../contexts/AuthContext";
import NewAdmin from "./NewAdmin";
import useWindowDimensions from "../utilities/useWindowDimensions";
import DeleteModal from "../Components/DeleteModal";

const AdminUsers = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  auth.authHandler();

  const [isRefresh, setIsRefresh] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState();
  const [activeAdmin, setActiveAdmin] = useState(null);
  const [adminList, setAdminList] = useState([]);
  const { width } = useWindowDimensions();
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const setRefresh = () => setIsRefresh(true);
  const refreshList = () => {
    setRefresh();
    setAlertType(ALERT_TYPES.SUCCESS);
    setAlertMessage("En ny admin er opprettet! 😎");
    setOpenAlert(true);
  };

  const createHandler = () => {
    navigate("ny-admin");
  };

  const createProperties = (data) => {
    const newData = data.map((admin) => {
      let newAdmin = {
        ...admin,
        fullName: `${admin.firstName} ${admin.lastName}`,
        role: admin.superUser ? "Super admin" : "",
      };
      return newAdmin;
    });

    return newData;
  };

  //get all admin
  useEffect(() => {
    setIsRefresh(false);

    try {
      fetch(process.env.REACT_APP_GET_ALL_ADMIN, {
        method: "GET",
        type: "cors",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            setAlertType(ALERT_TYPES.ERROR);
            setAlertMessage("Oops, kunne ikke hente adminbrukere.");
            setOpenAlert(true);
          }
        })
        .then((data) => {
          setAdminList(createProperties(data));
        });
    } catch (error) {
      console.error("Error: ", error);
      setAlertType(ALERT_TYPES.ERROR);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setOpenAlert(true);
    }

    const activeAdmin = async () => {
      const response = await fetch(process.env.REACT_APP_GET_ACTIVE_ADMIN, {
        method: "GET",
        type: "cors",
        credentials: "include",
      });

      if (response.status === 200) {
        const data = await response.json();
        await setActiveAdmin(data);
      }
    };

    try {
      activeAdmin();
    } catch (error) {
      console.error("Error:", error);
    }
  }, [isRefresh]);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = (reason, modalLoading) => {
    if (modalLoading && reason === "backdropClick") {
      return;
    }
    setDeleteModal(false);
  };

  const columns = () => {
    const desktop = [
      {
        field: "fullName",
        headerName: "Navn",
        flex: true,
        // width: 230,
      },
      {
        field: "email",
        headerName: "E-postadresse",
        flex: true,
        // width: 230,
      },
      {
        field: "role",
        headerName: "Tilgang",
        headerAlign: "center",
        renderCell: (params) => {
          if (params.row.role) {
            return (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: { xs: "center", lg: "center" },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: { xs: "45%" },
                    width: { xs: "auto" },
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 4,
                    px: { xs: 1, sm: 2 },
                    textTransform: "normal",
                    fontSize: 12,
                    fontWeight: 700,
                    letterSpacing: 0.5,
                    color: "#006857",
                    backgroundColor: "#abf5d1",
                  }}
                >
                  {params.row.role}
                </Box>
              </Box>
            );
          }
        },
        flex: true,
        // width: 160,
      },
    ];
    const mobile = [
      {
        field: "fullName",
        headerName: "Navn",
        width: 150,
      },
      {
        field: "email",
        headerName: "E-postadresse",
        width: 150,
      },
      {
        field: "role",
        headerName: "Tilgang",
        headerAlign: "center",
        renderCell: (params) => {
          if (params.row.role) {
            return (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: { xs: "center", lg: "center" },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: { xs: "45%" },
                    width: { xs: "auto" },
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 4,
                    px: { xs: 1, sm: 2 },
                    textTransform: "normal",
                    fontSize: 12,
                    fontWeight: 700,
                    letterSpacing: 0.5,
                    color: "#006857",
                    backgroundColor: "#abf5d1",
                  }}
                >
                  Super
                </Box>
              </Box>
            );
          }
        },
        width: 120,
      },
    ];

    if (width > 1200) {
      return desktop;
    }
    return mobile;
  };

  return (
    <>
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseDeleteModal}
        refresh={setRefresh}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
        setOpenAlert={setOpenAlert}
        item={"Admin"}
        idList={selectedRows}
        url={process.env.REACT_APP_DELETE_ADMIN}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: { xs: 0, md: 240 },
          pt: { xs: "76px", md: 3 },
          pb: { xs: 0, sm: 3 },
          px: { xs: 4 },
          height: "100vh",
          width: { xs: "100vw", md: "calc(100vw - 240px)" },
          overflow: "hidden",
        }}
      >
        <Alert
          state={openAlert}
          setState={setOpenAlert}
          type={alertType}
          duration={2000}
          message={alertMessage}
        />
        <Box container sx={{ p: { xs: 0, sm: 2 } }}>
          <Typography variant="h1">Adminbruker</Typography>

          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            sx={{
              mt: { xs: 6, md: 12 },
              visibility: `${activeAdmin?.superUser ? "visible" : "hidden"}`,
            }}
          >
            {/* Delete Button */}

            <Button
              size="small"
              sx={{ maxHeight: 30.75, px: { xs: 3, sm: 2 } }}
              disabled={!(selectedRows.length > 0)}
              variant="contained"
              onClick={handleOpenDeleteModal}
              endIcon={<DeleteIcon />}
            >
              Slett
            </Button>

            <Button
              onClick={createHandler}
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                display: { xs: "none", sm: "flex" },

                mt: { xs: 6, md: 12 },
              }}
            >
              opprett ny admin
            </Button>
          </Stack>

          {/* Table of all admin */}
          <Paper
            sx={{
              height: { xs: "60vh", sm: "55vh" },
              mt: 2,
            }}
          >
            <DataGrid
              rows={adminList}
              columns={columns()}
              onSelectionModelChange={(ids) => {
                setSelectedRows(ids);
              }}
              checkboxSelection={activeAdmin?.superUser}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              rowsPerPageOptions={[]}
              hideFooterPagination
              hideFooter={width < 1200}
              localeText={{
                footerRowSelected: (count) =>
                  count !== 1
                    ? `${count.toLocaleString()} rader valgt`
                    : `${count.toLocaleString()} rad valgt`,
                noRowsLabel: "",
              }}
            />
          </Paper>
        </Box>

        {/* Mobile: Add button */}
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{ mt: 4, display: { xs: "flex", sm: "none" } }}
        >
          <Button
            onClick={createHandler}
            variant="contained"
            endIcon={<AddIcon />}
          >
            Opprett ny admin
          </Button>
        </Stack>

        <Routes>
          <Route
            path="ny-admin"
            element={
              <ProtectedRoute>
                <NewAdmin refreshList={refreshList} />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
    </>
  );
};

export default AdminUsers;
