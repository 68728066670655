import { useState } from "react";
import { Stack, TextField, Paper, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";

const ResetPasswordForm = ({ isAdminResetPage }) => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const token = search.get("token");
  const [passwords, setPasswords] = useState({
    password_1: "",
    password_2: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");

  const handleOnChange = (e) => {
    const { value, name } = e.currentTarget;
    setPasswords((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const keyDownHandler = (e) => e.key === "Enter" && handleSend();

  const handleSend = async () => {
    const url = isAdminResetPage
      ? process.env.REACT_APP_RESET_ADMIN_PASSWORD
      : process.env.REACT_APP_RESET_USER_PASSWORD;

    if (passwords.password_1 === "" || passwords.password_2 === "") {
      setIsError("Vennligst fyll inn begge felt.");
      return;
    } else if (
      passwords.password_1.length < 8 ||
      passwords.password_2.length < 8
    ) {
      setIsError("Passord må være minst 8 tegn");
      return;
    } else if (passwords.password_1 !== passwords.password_2) {
      setIsError("Passordene er ikke like. Prøv igjen.");
      return;
    }

    console.log(
      "SENDER: ",
      JSON.stringify({
        token: token,
        password: passwords.password_1,
        repeatedPassword: passwords.password_2,
      })
    );

    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        type: "cors",
        credentials: "include",
        body: JSON.stringify({
          token: token,
          password: passwords.password_1,
          repeatedPassword: passwords.password_2,
        }),
        headers: { "Content-Type": "application/json" },
      });
      setIsLoading(false);

      if (response.status === 200) {
        navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Paper elevation={15} onKeyDown={keyDownHandler}>
      <Stack
        height={450}
        spacing={{ xs: 4, sm: 6 }}
        backgroundColor="white"
        py={6}
        px={5}
        borderRadius={2}
        elevation={2}
        justifyContent="center"
      >
        {/* Heading */}
        <Typography
          variant="h1"
          color="secondary.dark"
          sx={{
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          Resett passord
        </Typography>

        {/* Password 1 */}
        <Stack spacing={3}>
          <TextField
            name="password_1"
            required
            onClick={() => setIsError("")}
            onChange={handleOnChange}
            value={passwords.password_1}
            error={!!isError}
            placeholder="Nytt passord"
            type={showPassword ? "text" : "password"}
          />

          {/* Password 2 */}
          <TextField
            name="password_2"
            required
            onClick={() => setIsError("")}
            onChange={handleOnChange}
            value={passwords.password_2}
            error={!!isError}
            helperText={isError}
            placeholder="Gjenta nytt passord"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {/* Button */}
        <LoadingButton
          loading={isLoading}
          onClick={handleSend}
          variant="contained"
          color="secondary"
          sx={{ py: 1 }}
        >
          Resett passord
        </LoadingButton>
      </Stack>
    </Paper>
  );
};

export default ResetPasswordForm;
