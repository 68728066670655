import { useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Button,
  Grid,
  IconButton,
  Box,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDateFns";
import nbLocale from "date-fns/locale/nb";

import Alert, { ALERT_TYPES } from "../Components/Alert";
import DragDrop from "../Components/trips/DragDrop";
import ImgBar from "../Components/trips/ImgBar";
import ContentContainer from "../Components/ContentContainer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const CreateTrip = ({ customerId, refreshList, setTripName, refreshLinks }) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [previewURL, setPreviewURL] = useState("");
  const [theme, setTheme] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [tripInfo, setTripInfo] = useState({
    tripName: "",
    mapCoordinates: "",
  });
  const [infoList, setInfoList] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [isImgBar, setIsImgBar] = useState(false);
  const [image, setImage] = useState(null);
  const [nameError, setNameError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  var dayjs = require("dayjs");

  const [isDisabled, setIsDisabled] = useState(true);
  const closeTrip = useCallback(() => {
    const pathname = location.pathname;
    const lastSlash = pathname.lastIndexOf("/");
    navigate(pathname.substr(0, lastSlash));
  }, [location.pathname, navigate]);
  //name/mapCoordinates
  const tripInfoHandler = (e) => {
    const { value, name } = e.currentTarget;
    setTripInfo((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const titleRef = useRef();
  const descriptionRef = useRef();
  const linkRef = useRef();

  //extra info list
  const addInfo = () => {
    const infoItem = {
      id: new Date().getTime(),
      title: titleRef.current.children[0].children[0].value,
      description: descriptionRef.current.children[0].children[0].value,
      link: linkRef.current.children[0].children[0].value,
    };

    setInfoList((prev) => [...prev, infoItem]);
    setExpanded(infoItem.id.toString());
    setIsDisabled(true);
    titleRef.current.children[0].children[0].value = "";
    descriptionRef.current.children[0].children[0].value = "";
    linkRef.current.children[0].children[0].value = "";
  };

  const deleteInfo = (id) => {
    const newInfos = [...infoList].filter((info) => info.id !== id);
    setInfoList(newInfos);
  };

  const handleExpanded = (id) => (e, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  //For drag&drop
  const removeFile = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
  };

  const addFile = (e) => {
    const newFiles = e.target.files;
    if (newFiles) {
      setFileList((prevState) => {
        return [...prevState, ...newFiles];
      });
    }
  };

  const handleFilesOrder = (list) => {
    setFileList(list);
  }

  //toggle
  const toggleHandler = (e, theme) => {
    if (theme !== null) {
      setTheme(theme);
    }
  };

  //img uploader
  const openImgBar = () => setIsImgBar(true);
  const closeImgBar = () => setIsImgBar(false);

  const imageHandler = (img, imgUrl) => {
    setImage(img);
    setPreviewURL(imgUrl);
  };

  const postImage = async () => {
    if (image instanceof File) {
      let formImg = new FormData();
      formImg.append("image", image);
      try {
        let response = await fetch(process.env.REACT_APP_ADD_IMAGE, {
          method: "POST",
          credentials: "include",
          type: "cors",
          body: formImg,
        });
        if (response.status === 200) {
          return await response.json();
        } else {
          setAlertMessage("Oops, kunne ikke laste opp bildet.");

          setOpenAlert(true);
          return null;
        }
      } catch (error) {
        console.error("Error:", error);
        setAlertMessage("Oops, noe gikk galt! 😰");
        setOpenAlert(true);
        return null;
      }
    }

    return null;
  };

  const postTrip = async (imageId) => {
    const formData = new FormData();
    formData.append("name", tripInfo.tripName);
    formData.append(
      "startDateUtc",
      dayjs(dateRange[0]).format().replace(/-/g, "/").replace(/T.+/, "")
    );
    formData.append(
      "endDateUtc",
      dayjs(dateRange[1]).format().replace(/-/g, "/").replace(/T.+/, "")
    );

    let newList = infoList.map((info, index) => ({
      title: info.title,
      description: info.description,
      link: info.link,
      order: index
    }));

    formData.append("travelTips", JSON.stringify(newList));

    formData.append("userId", customerId);

    imageId && formData.append("imageId", imageId);

    for (var i = 0; i < fileList.length; i++) {
      formData.append("files", fileList[i]);
    }

    formData.append("theme", theme);
    formData.append("MapCoordinates", tripInfo.mapCoordinates);

    if (!dateRange[0] || !dateRange[1]) {
      setAlertMessage("Dato kan ikke være tom!");

      setOpenAlert(true);
    }

    if (tripInfo.tripName === "") {
      setNameError("Reisenavn kan ikke være tom");
    }

    if (dateRange[0] && dateRange[1] && tripInfo.tripName) {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_CREATE_TRIP}`, {
          method: "POST",
          type: "cors",
          credentials: "include",
          body: formData,
        });
        if (response.status === 201) {
          setTripName(`Reisen "${tripInfo.tripName}" er opprettet! 🎉`);
          setIsLoading(false);
          refreshList();
          closeTrip();
        } else {
          setAlertMessage("Oops, kunne ikke opprette reisen.");
          setOpenAlert(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setAlertMessage("Oops, noe gikk galt! 😰");
        setOpenAlert(true);
        setIsLoading(false);
      }
    }
  };

  const createTripHandler = async () => {
    if (image === null) {
      postTrip(null);
      return;
    }
    const dbImg = await postImage();
    if (!(image instanceof File) && dbImg === null) {
      postTrip(image.id);
      return;
    }
    postTrip(dbImg.id);
  };

  const moveUp = (index) => {
    if (index > 0) {
      _swap(infoList, index, index - 1);
    }
  };

  const moveDown = (index) => {
    if (index < infoList.length - 1) {
      _swap(infoList, index, index + 1);
    }
  };

  const _swap = (obj, prop1, prop2) => {
    var tmp = obj[prop1];
    obj[prop1] = obj[prop2];
    obj[prop2] = tmp;
    const newInfo = obj;
    setInfoList(newInfo);
    refreshLinks();

  };

   const onDragEnd = (result) => {
     if (!result.destination) {
       return;
     }

     const orderedItems = reorder(
       infoList,
       result.source.index,
       result.destination.index
     );
     setInfoList(
       orderedItems.map((item, index) => ({ ...item, order: index }))
     );
   };

   const reorder = (list, startIndex, endIndex) => {
     const result = Array.from(list);
     const [removed] = result.splice(startIndex, 1);
     result.splice(endIndex, 0, removed);

     return result;
   };

  return (
    <ContentContainer title={"Opprett ny reise"} isLoading={isLoading}>
      <Box sx={{ width: { xs: "100%", lg: "60%" }, px: 2, pb: 2 }}>
        <Grid
          container
          rowSpacing={{ xs: 3, md: 4 }}
          columnSpacing={4}
          py={{ xs: 2, sm: 0 }}
        >
          {/* previewImg & button to open ImgSidebar, check if admin is superadmin*/}
          <Grid item xs={12}>
            <Typography variant="h4">bilde</Typography>
            {!previewURL ? (
              <Button
                onClick={openImgBar}
                variant="outlined"
                sx={{
                  width: { xs: "100%", sm: "60%", md: "50%" },
                  height: "20vh",
                  fontSize: "16px",
                  textTransform: "none",
                }}
              >
                Trykk her for å velge bilde
              </Button>
            ) : (
              <Box position="relative">
                <Button
                  onClick={openImgBar}
                  variant="outlined"
                  sx={{
                    width: { xs: "100%", sm: "60%", md: "50%" },

                    height: "20vh",
                    border: "1px dashed",
                    padding: 1,
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    src={previewURL}
                    alt="img"
                  />
                </Button>
              </Box>
            )}

            <ImgBar
              isImg={isImgBar}
              closeImgBar={closeImgBar}
              imageHandler={imageHandler}
            />
          </Grid>

          {/* Toggle button*/}
          {/* TODO: make color more prominent */}
          <Grid item xs={12}>
            <Typography variant="h4">Temafarge</Typography>
            <ToggleButtonGroup
              size="small"
              color="info"
              value={theme}
              exclusive
              onChange={toggleHandler}
            >
              <ToggleButton
                value={false}
                color="secondary"
                sx={{
                  fontWeight: "bold",

                  "&.MuiToggleButton-root.Mui-selected": {
                    backgroundColor: "rgba(144, 114, 93, 0.3)",
                  },
                }}
              >
                Brun
              </ToggleButton>
              <ToggleButton
                value={true}
                color="primary"
                sx={{
                  fontWeight: "bold",

                  "&.MuiToggleButton-root.Mui-selected": {
                    backgroundColor: "rgba(33, 131, 126, 0.3)",
                  },
                }}
              >
                Grønn
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          {/* date-picker */}
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={DateAdapter} locale={nbLocale}>
              <DateRangePicker
                disablePast
                mask="__.__.____"
                startText=""
                endText=""
                value={dateRange}
                onChange={(newValue) => {
                  setDateRange(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <Stack direction="row" spacing={4} width="100%">
                    <Stack sx={{ width: "100%" }}>
                      <Typography variant="h4">Startdato</Typography>
                      <TextField {...startProps} />
                    </Stack>
                    <Stack sx={{ width: "100%" }}>
                      <Typography variant="h4">Sluttdato</Typography>
                      <TextField {...endProps} />
                    </Stack>
                  </Stack>
                )}
              />
            </LocalizationProvider>
          </Grid>

          {/* name-input */}
          <Grid item xs={6}>
            <Typography variant="h4">navn</Typography>
            <TextField
              error={!!nameError}
              helperText={nameError}
              onClick={() => setNameError("")}
              id="name"
              name="tripName"
              placeholder="Reisenavn"
              variant="outlined"
              onChange={tripInfoHandler}
              value={tripInfo.tripName}
              required
              sx={{ width: "100%" }}
            />
          </Grid>

          {/* map link-input */}
          <Grid item xs={6}>
            <Typography variant="h4">koordinater</Typography>
            <TextField
              placeholder="Koordinater fra kart"
              variant="outlined"
              required
              name="mapCoordinates"
              onChange={tripInfoHandler}
              value={tripInfo.mapCoordinates}
              sx={{ width: "100%" }}
            />
          </Grid>

          {/* Create info list item */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4">Reisetips</Typography>

            <Stack spacing={2}>
              <TextField
                ref={titleRef}
                placeholder="Tittel"
                onChange={(e) => {
                  e.currentTarget.value
                    ? setIsDisabled(false)
                    : setIsDisabled(true);
                }}
                variant="outlined"
              />
              <TextField
                ref={descriptionRef}
                placeholder="Beskrivelse"
                variant="outlined"
                multiline
                rows={6}
              />
              <TextField ref={linkRef} placeholder="Lenke" variant="outlined" />
            </Stack>
            <Button
              onClick={addInfo}
              disabled={isDisabled}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Legg til
            </Button>
          </Grid>
          {/* Extra info list */}

          <Grid item xs={12} md={5} sx={{ mt: { xs: 0, md: 5.8 } }}>
            {infoList.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {infoList
          
                        .map((info, index) => {
                          return (
                            <Draggable
                              key={info.id}
                              draggableId={info.id.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Accordion
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  expanded={expanded === info.id.toString()}
                                  onChange={handleExpanded(info.id.toString())}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ wordBreak: "break-word" }}
                                  >
                                    <Typography
                                      variant="h4"
                                      fontSize={16}
                                      p={0}
                                      pr={1}
                                    >
                                      {info.title}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{ position: "relative", pb: 7 }}
                                  >
                                    <Stack spacing={1}>
                                      <Typography
                                        variant="h4"
                                        fontSize={16}
                                        p={0}
                                      >
                                        Beskrivelse
                                      </Typography>
                                      <Typography>
                                        {info.description}
                                      </Typography>
                                      <Typography
                                        variant="h4"
                                        fontSize={16}
                                        p={0}
                                        paddingTop={2}
                                      >
                                        Link
                                      </Typography>
                                      <Typography
                                        sx={{ wordBreak: "break-all" }}
                                      >
                                        {info.link}
                                      </Typography>
                                      <IconButton
                                        onClick={() => deleteInfo(info.id)}
                                        sx={{
                                          position: "absolute",
                                          bottom: 8,
                                          left: 8,
                                        }}
                                      >
                                        <DeleteIcon sx={{ fontSize: 24 }} />
                                      </IconButton>
                                      <AccordionDetails></AccordionDetails>
                                      <IconButton
                                        onClick={() => moveUp(index)}
                                        sx={{
                                          position: "absolute",
                                          bottom: 40,
                                          right: 8,
                                        }}
                                      >
                                        <ArrowUpwardIcon
                                          sx={{ fontSize: 24 }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => moveDown(index)}
                                        sx={{
                                          position: "absolute",
                                          bottom: 8,
                                          right: 8,
                                        }}
                                      >
                                        <ArrowDownwardIcon
                                          sx={{ fontSize: 24 }}
                                        />
                                      </IconButton>
                                    </Stack>
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </Draggable>
                          );
                        })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Grid>

          {/* Drag&Drop */}

          <DragDrop
            fileList={fileList}
            handleFilesOrder={handleFilesOrder}
            fileDrop={addFile}
            dropClose={removeFile}
            refreshLinks={refreshLinks}
          />

          {/* Create trip button */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
            }}
          >
            <LoadingButton
              loading={isLoading}
              variant="contained"
              sx={{
                mt: { xs: 4, md: 12 },
                width: { xs: "80%", md: "0" },
                px: 6,
              }}
              onClick={createTripHandler}
            >
              Opprett
            </LoadingButton>
          </Grid>
        </Grid>
        {/* ERROR ALERTS */}
        <Alert
          state={openAlert}
          setState={setOpenAlert}
          type={ALERT_TYPES.ERROR}
          duration={2000}
          message={alertMessage}
        />
      </Box>
    </ContentContainer>
  );
};

export default CreateTrip;
