import React, { useState, useRef } from "react";
import ContentContainer from "../Components/ContentContainer";
import { Typography, TextField, Grid, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router";
import Alert, { ALERT_TYPES } from "../Components/Alert";

const NewAdmin = ({ refreshList }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSuper, setIsSuper] = useState(true);
  const [admin, setAdmin] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const password = useRef();
  const repeatPassword = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isError, setIsError] = useState({
    firstNameError: "",
    emailError: "",
    passwordError: "",
    repeatError: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const adminHandler = (e) => {
    const { value, name } = e.currentTarget;
    setAdmin((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const superHandler = (e) => {
    setIsSuper(e.target.checked);
    console.log(e.target.checked);
  };

  const closeAdmin = () => {
    const pathname = location.pathname;
    const lastSlash = pathname.lastIndexOf("/");
    navigate(pathname.substr(0, lastSlash));
  };

  const createHandler = () => {
    const enteredPassword = password.current.children[1].children[0].value;
    const enteredRepeatPassword =
      repeatPassword.current.children[1].children[0].value;

    if (admin.firstName === "") {
      setIsError((prevState) => {
        return { ...prevState, firstNameError: "Fornavn kan ikke være tom" };
      });
    }
    if (admin.email === "") {
      setIsError((prevState) => {
        return { ...prevState, emailError: "Epost kan ikke være tom" };
      });
    }

    if (enteredPassword.length < 8) {
      setIsError((prevState) => {
        return { ...prevState, passwordError: "passord må være minst 8 tegn" };
      });
    } else if (enteredPassword !== enteredRepeatPassword) {
      setIsError((prevState) => {
        return {
          ...prevState,
          passwordError: "passord er ikke like",
          repeatError: "passord er ikke like",
        };
      });
    }

    if (enteredRepeatPassword.length < 8) {
      setIsError((prevState) => {
        return { ...prevState, repeatError: "passord må være minst 8 tegn" };
      });
    } else if (enteredPassword !== enteredRepeatPassword) {
      setIsError((prevState) => {
        return {
          ...prevState,
          passwordError: "passord er ikke like",
          repeatError: "passord er ikke like",
        };
      });
    }

    if (
      admin.firstName &&
      admin.email &&
      enteredPassword === enteredRepeatPassword &&
      enteredRepeatPassword.length >= 8 &&
      enteredRepeatPassword.length >= 8
    ) {
      try {
        fetch(process.env.REACT_APP_CREATE_ADMIN, {
          method: "POST",
          type: "cors",
          credentials: "include",
          body: JSON.stringify({
            email: admin.email,
            firstName: admin.firstName,
            lastName: admin.lastName,
            password: enteredPassword,
            superUser: isSuper,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res.status === 201) {
            refreshList();
            closeAdmin();
          } else if (res.status === 400) {
            setAlertMessage("Denne e-postadressen finnes allerede i systemet!");
            setOpenAlert(true);
          } else {
            setAlertMessage("Oops, kunne ikke opprette adminbruker.");
            setOpenAlert(true);
          }
        });
      } catch (error) {
        console.error("Error: ", error);
        setAlertMessage("Oops, noe gikk galt! 😰");
        setOpenAlert(true);
      }
    }
  };

  return (
    <>
      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={ALERT_TYPES.ERROR}
        duration={8000}
        message={alertMessage}
      />
      <ContentContainer title={"Opprett ny admin"}>
        <Box
          sx={{
            width: { xs: "100%", lg: "60%" },
            px: 2,
            mt: { xs: 0, sm: 8, md: 6 },
          }}
        >
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 3, md: 4 }}
            columnSpacing={6}
            py={{ xs: 2, sm: 0 }}
          >
            {/* name-input */}
            <Grid item xs={12} sm={4} md={6}>
              <Typography variant="h4">Fornavn</Typography>
              <TextField
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, firstNameError: "" };
                  })
                }
                error={!!isError.firstNameError}
                helperText={isError.firstNameError}
                id="firstName"
                name="firstName"
                placeholder="Fornavn"
                variant="outlined"
                onChange={adminHandler}
                value={admin.firstName}
                required
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={6}>
              <Typography variant="h4">Etternavn</Typography>

              <TextField
                id="lastName"
                name="lastName"
                placeholder="Etternavn"
                variant="outlined"
                onChange={adminHandler}
                value={admin.lastName}
                required
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={12}>
              <Typography variant="h4">E-postadresse</Typography>

              <TextField
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, emailError: "" };
                  })
                }
                error={!!isError.emailError}
                helperText={isError.emailError}
                id="email"
                name="email"
                placeholder="E-postadresse"
                variant="outlined"
                onChange={adminHandler}
                value={admin.email}
                required
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* Input Password */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">Passord</Typography>

              <TextField
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, passwordError: "", repeatError: "" };
                  })
                }
                error={!!isError.passwordError}
                helperText={isError.passwordError}
                required
                id="outlined-password"
                label="Passord"
                type={showPassword ? "text" : "password"}
                ref={password}
                autoComplete="current-password"
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h4">Gjenta passord</Typography>
              <TextField
                onClick={() =>
                  setIsError((prevState) => {
                    return { ...prevState, passwordError: "", repeatError: "" };
                  })
                }
                error={!!isError.repeatError}
                helperText={isError.repeatError}
                required
                id="outlined-repeat"
                label="Gjenta passord"
                type={showRepeatPassword ? "text" : "password"}
                ref={repeatPassword}
                autoComplete="current-password"
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRepeatPassword}
                        edge="end"
                      >
                        {showRepeatPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Toggle button*/}
            <Grid item xs={12}>
              <Typography variant="h4">Superadmin</Typography>
              <Switch
                checked={isSuper}
                onChange={superHandler}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>

            {/* Create button */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              <LoadingButton
                variant="contained"
                sx={{
                  my: 3,
                  width: { xs: 200, md: 0 },
                  px: 6,
                }}
                onClick={createHandler}
              >
                Opprett
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </ContentContainer>
    </>
  );
};

export default NewAdmin;
