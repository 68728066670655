import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#21837E",
    },
    secondary: {
      main: "#90725D",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },

  typography: {
    fontFamily: "Mulish",
    h1: {
      fontFamily: "Playfair Display",
      fontSize: "2rem",
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },

      fontWeight: 800,
      letterSpacing: "0.5px",
    },

    h2: {
      fontFamily: "Playfair Display",
      fontSize: "18px",
      fontWeight: 800,
    },
    h3: {
      fontFamily: "Playfair Display",
      fontSize: "18px",
      fontWeight: 100,
    },
    h4: {
      fontSize: "14px",
      letterSpacing: 0.5,
      padding: "14px 0px",
      fontWeight: 700,
      textTransform: "uppercase",
      color: "rgba(0,0,0,0.7)",
    },

    p: {
      fontFamily: "Mulish",
      fontSize: "1rem",
    },
  },
});

export default theme;
