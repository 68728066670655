import { useRef, useState } from "react";
import uploadImg from "../../assets/cloud-upload-regular-240.png";
import {
  Stack,
  Box,
  Divider,
  IconButton,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Alert, { ALERT_TYPES } from "../Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import convertSizeToByte from "../../utilities/convertSizeToByte";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";

const DragDrop = ({
  dropClose,
  fileList,
  handleFilesOrder,
  fileDrop,
  refreshLinks,
}) => {
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const path = useLocation().pathname;

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState();
  const [openAlert, setOpenAlert] = useState(false);

  const downloadFile = async (selectedFile) => {
    const creatingTrip = path.includes("ny-reise") ? true : false;

    if (creatingTrip) {
      saveAs(selectedFile, selectedFile.name);
    } else {
      try {
        fetch(`${process.env.REACT_APP_SAVE_FILE}/${selectedFile.id}`, {
          method: "GET",
          type: "cors",
          credentials: "include",
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", selectedFile.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          });
      } catch (e) {
        console.log("Error", e);
        setAlertMessage("Oops, noe gikk galt! 😰");
        setOpenAlert(true);
        setAlertType(ALERT_TYPES.ERROR);
      }
    }
  };

  const moveUp = (file, index) => {
    if (index > 0) {
      changeOrder(fileList, index, index - 1);
    }
  };

  const moveDown = (file, index) => {
    if (index < fileList.length - 1) {
      changeOrder(fileList, index, index + 1);
    }
  };

  const changeOrder = (obj, prop1, prop2) => {
    const creatingTrip = path.includes("ny-reise") ? true : false;

    var tmp = obj[prop1];
    obj[prop1] = obj[prop2];
    obj[prop2] = tmp;
    const newInfo = obj;
    if (creatingTrip) {
      // newInfo.map((item, index) => ({ ...item, order: index }));
      handleFilesOrder(newInfo);
    } else {
      handleFilesOrder(
        newInfo.map((item, index) => ({ ...item, order: index }))
      );
    }
    refreshLinks();
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(
      fileList,
      result.source.index,
      result.destination.index
    );
    const creatingTrip = path.includes("ny-reise");

    if (creatingTrip) {
      handleFilesOrder(orderedItems);
    } else {
      handleFilesOrder(
        orderedItems.map((item, index) => ({ ...item, order: index }))
      );
    }
    //setInfoList(orderedItems);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const checkFileList = () => {
    if (fileList[0]?.order == 0 && fileList[1]?.order == 0) {
      fileList.sort((a, b) => (a.order > b.order ? -1 : 1));
      return fileList;
    } else if (path.includes("ny-reise")) {
      return fileList;
    } else {
      return fileList.sort((a, b) => (a.order > b.order ? 1 : -1));
    }
  };

  return (
    <>
      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={alertType}
        duration={2000}
        message={alertMessage}
      />
      <Grid item xs={12} md={6}>
        <Typography variant="h4">Dokumenter</Typography>
        <Box
          position="relative"
          ref={wrapperRef}
          className={"dragDrop"}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <Stack
            sx={{
              borderRadius: 2,
              border: "2px dashed #d3d3d3",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 160,
            }}
          >
            <img width="60px" src={uploadImg} alt="" />
            <Typography variant="p">Last opp fil</Typography>
          </Stack>
          <input
            type="file"
            accept=".pdf"
            value=""
            onChange={fileDrop}
            multiple
          />
        </Box>
      </Grid>

      {fileList !== null && fileList.length > 0 && (
        <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 5.75 }}>
          <Stack
            sx={{
              width: "100%",
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: 3,
              minWidth: 300,
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {checkFileList().map((file, index) => (
                      <Draggable
                        key={index}
                        draggableId={`ID+${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            display={"flex"}
                            flexDirection={"column"}
                            key={index}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Stack
                                key={index}
                                sx={{ px: 2, py: 1, width: "100%" }}
                              >
                                <Grid
                                  container
                                  columnSpacing={10}
                                  alignItems="center"
                                >
                                  <Grid item xs={6} sm={7}>
                                    <Typography>{file.name}</Typography>
                                    <Button
                                      style={{ right: 20 }}
                                      onClick={() => {
                                        downloadFile(file, file.name);
                                      }}
                                    >
                                      {" "}
                                      <DownloadIcon />
                                    </Button>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Typography
                                      variant="h4"
                                      sx={{ textAlign: "right" }}
                                    >
                                      {convertSizeToByte(file.size)}
                                    </Typography>
                                  </Grid>

                                  {/* <Grid item xs={2} sm={1}>
                      <IconButton onClick={() => dropClose(file)}>
                        <DeleteIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Grid> */}
                                </Grid>
                              </Stack>

                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems="center"
                              >
                                <IconButton onClick={() => dropClose(file)}>
                                  <DeleteIcon
                                    sx={{
                                      fontSize: 20,
                                    }}
                                  />
                                </IconButton>
                                <Box display={"flex"} flexDirection="column">
                                  <IconButton
                                    onClick={() => moveUp(file, index)}
                                  >
                                    <ArrowUpward sx={{ fontSize: 25 }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => moveDown(file, index)}
                                  >
                                    <ArrowDownward sx={{ fontSize: 25 }} />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                            {index !== fileList.length - 1 && (
                              <Divider width="100%" />
                            )}
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default DragDrop;
