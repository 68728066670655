import { useState } from "react";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ALERT_TYPES } from "./Alert";

const SendInviteButton = ({
  params,
  refreshList,
  setAlertType,
  setAlertMessage,
  setOpenAlert,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    //  invited,
    firstName,
    verified,
    id,
  } = params.row;

  const handleInvite = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SEND_INVITATION}/${id}`,
        {
          method: "POST",
          type: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        refreshList();
        setAlertMessage(`Invitasjon er sendt til ${firstName}`);
        setAlertType(ALERT_TYPES.SUCCESS);
        setOpenAlert(true);
      } else {
        setAlertMessage("Oops, kunne ikke sende invitasjon til kunden.");
        setAlertType(ALERT_TYPES.ERROR);
        setOpenAlert(true);
      }
    } catch (error) {
      setIsLoading(false);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setAlertType(ALERT_TYPES.ERROR);
      setOpenAlert(true);
      console.error("Error:", error);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    handleInvite(id);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingButton
        loading={isLoading}
        sx={{
          p: 0,
          minHeight: 30,
          textTransform: "none",
          fontSize: 14,
          fontWeight: 700,
        }}
        onClick={(e) => {
          handleClick(e);
        }}
        disableElevation
        disabled={verified}
        variant="text"
      >
        {verified ? "Sendt" : "Send"}
      </LoadingButton>
    </Box>
  );
};

export default SendInviteButton;
