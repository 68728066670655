import { useState, useEffect, useRef } from "react";
import {
  Button,
  IconButton,
  Slide,
  Typography,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../DeleteModal";
import Alert, { ALERT_TYPES } from "../Alert";

const ImgBar = ({ isImg, closeImgBar, imageHandler }) => {
  const selfRef = useRef();

  const [deleteId, setDeleteId] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [imgList, setImgList] = useState(null);
  const [uploadImageName, setUploadImageName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeAdmin, setActiveAdmin] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const setRefresh = () => setRefreshList(true);
  //fetch all image
  useEffect(() => {
    setRefreshList(false);
    try {
      fetch(`${process.env.REACT_APP_GET_IMAGES}`, {
        method: "GET",
        type: "cors",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 404) {
            setImgList(null);
          } else {
            setAlertMessage("Oops, kunne ikke hente bilder.");
            setOpenAlert(true);
          }
        })
        .then((data) => {
          data !== null && setImgList(data);
        });
    } catch (error) {
      console.error("Error:", error);
      setAlertMessage("Oops, noe gikk galt! 😰");
      setOpenAlert(true);
    }

    const activeAdmin = async () => {
      const response = await fetch(process.env.REACT_APP_GET_ACTIVE_ADMIN, {
        method: "GET",
        type: "cors",
        credentials: "include",
      });

      if (response.status === 200) {
        const data = await response.json();
        setActiveAdmin(data);
      }
    };
    try {
      activeAdmin();
    } catch (error) {
      console.error("Error:", error);
    }
  }, [refreshList]);

  // LAST OPP
  const handleImg = (e) => {
    imageHandler(e.target.files[0], URL.createObjectURL(e.target.files[0]));
    setUploadImageName(e.target.files[0].name);
  };

  // SELECT IMG
  const handleImgUploaded = (img) => {
    imageHandler(img, img.url);
    setUploadImageName("");
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteId([id]);
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = (reason, modalLoading) => {
    if (reason === "backdropClick" && modalLoading) {
      return;
    }
    setDeleteModal(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!selfRef.current.contains(e.target)) {
        closeImgBar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={selfRef}>
      <Alert
        state={openAlert}
        setState={setOpenAlert}
        type={ALERT_TYPES.ERROR}
        duration={2000}
        message={alertMessage}
      />
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseDeleteModal}
        refresh={setRefresh}
        idList={deleteId}
        removePreview={imageHandler}
        url={process.env.REACT_APP_DELETE_IMAGE}
      />
      <Slide
        direction="left"
        in={isImg}
        timeout={700}
        mountOnEnter
        unmountOnExit
        sx={{
          position: "fixed",
          top: { xs: 56, md: 0 },
          right: 0,
          background: "#f6fafa",
          p: { xs: 1, sm: 3, md: 4 },
          height: { xs: "calc(100% - 56px)", md: "100%" },
          width: { xs: "100%", md: "50%", lg: "35%" },
          boxShadow: "0px -2px 6px rgba(0,0,0,0.4)",
          zIndex: 200,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Back button and Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
            }}
          >
            <IconButton aria-label="go-back" onClick={closeImgBar}>
              <ChevronLeftIcon sx={{ fontSize: 30, color: "#000000" }} />
            </IconButton>

            <Typography variant="h2">Velg reisebilde</Typography>
          </Box>

          {/* Image Grid */}
          <Grid
            container
            sx={{
              py: 2,
              px: 1,
              borderRadius: 2,
              background: "rgba(0,0,0,0.03)",
              overflowY: "auto",
              maxHeight: "calc(100% - 200px)",
              justifyContent: "center",
            }}
          >
            {imgList !== null && imgList?.length > 0 ? (
              imgList.map((img) => (
                <Grid
                  key={img.id}
                  item
                  xs={12}
                  sm={4}
                  md={6}
                  sx={{
                    padding: { xs: 1 },
                    position: "relative",
                    height: 200,
                  }}
                >
                  <Button
                    className={"tripImage"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      boxShadow: 2,
                      padding: 0,
                    }}
                    onClick={() => {
                      handleImgUploaded(img);
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 8,
                      }}
                      src={img.url}
                      alt="img"
                    />
                  </Button>
                  {activeAdmin?.superUser && (
                    <IconButton
                      className={"imgDeleteBtn"}
                      variant="contained"
                      sx={{
                        visibility: { xs: "visible", md: "hidden" },
                        color: "#6C6C6C",
                        position: "absolute",
                        right: 20,
                        top: 20,
                        boxShadow: 2,
                      }}
                      onClick={() => handleOpenDeleteModal(img.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              ))
            ) : (
              <Typography variant="h2" color="rgba(0,0,0,0.6)">
                Ingen reisebilder
              </Typography>
            )}
          </Grid>

          {activeAdmin?.superUser && (
            <Stack
              sx={{
                mt: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                px: 2,
              }}
              spacing={2}
            >
              <label className="customUpload">
                <input type="file" accept="image/*" onChange={handleImg} />
                Last opp bilde
              </label>
              {uploadImageName && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    background: "rgba(255,255,255,1)",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: 2,
                    py: 0.5,
                    px: 2,
                  }}
                >
                  Filnavn:
                  <Typography noWrap width="100%">
                    {`\xa0 ${uploadImageName}`}
                  </Typography>
                </Box>
              )}
            </Stack>
          )}
        </Box>
      </Slide>
    </div>
  );
};
// )
export default ImgBar;
