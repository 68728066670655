import React, { useState } from "react";

const AuthContext = React.createContext({
  isAuth: false,

  authHandler: () => {},
});

export const AuthContextProvider = (props) => {
  const [isAuth, setIsAuth] = useState(null);

  const authHandler = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_AUTHENTICATE, {
        method: "GET",
        type: "cors",
        credentials: "include",
      });

      response.status === 200 ? setIsAuth(true) : setIsAuth(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const contextValue = {
    isAuth: isAuth,
    authHandler: authHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
