import { Box } from "@mui/material";
import useWindowDimensions from "../utilities/useWindowDimensions";

const StatusTag = (params) => {
  const { invited, verified } = params.row;
  const { width } = useWindowDimensions();

  let text = "";
  let color = "";
  let bgColor = "";

  if (!invited && !verified) {
    text = "Ikke registrert";
    color = "#4f6182";
    bgColor = "#ebecf0";
  } else if (invited && verified) {
    text = "Registrert";
    color = "#006857";
    bgColor = "#abf5d1";
  } else {
    text = "Avventer";
    color = "#654d00";
    bgColor = "#ffe9a1";
  }

  if (width < 900) text = "";
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: { xs: 24, md: "45%" },
          width: { xs: 24, md: "auto" },
          display: "flex",
          alignItems: "center",
          borderRadius: 4,
          px: { xs: 1, md: 2 },
          textTransform: "normal",
          fontSize: 12,
          fontWeight: 700,
          letterSpacing: 0.5,
          color: color,
          backgroundColor: bgColor,
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default StatusTag;
