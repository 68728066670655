import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, IconButton, Divider, Skeleton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const ContentContainer = ({ children, isLoading, title }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const closeContainer = (pathname) => {
    const lastSlash = pathname.lastIndexOf("/");
    navigate(pathname.substr(0, lastSlash));
  };

  return (
    <Box
      sx={{
        pt: { xs: "76px", md: 3 },
        pb: { xs: 0, sm: 3 },
        px: { xs: 2 },
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: { xs: "100vw", md: "calc(100vw - 240px)" },
        background: "white",
        display: "flex",
        flexDirection: "column",
        zIndex: 2,
      }}
    >
      {/* Back button and Titles */}
      <Box sx={{ display: "flex", alignItems: "center", px: { xs: 1, sm: 0 } }}>
        <IconButton
          disabled={isLoading}
          aria-label="go-back"
          onClick={() => closeContainer(location.pathname)}
          sx={{ p: { xs: 0, sm: 1 } }}
        >
          <ChevronLeftIcon
            sx={{
              fontSize: { xs: 34, sm: 50 },
              color: `${isLoading ? "grey" : "#000000"}`,
            }}
          />
        </IconButton>

        <Typography noWrap variant="h1" sx={{ ml: 1 }}>
          {title}
        </Typography>
        {!title && <Skeleton width={300} height={55} />}
      </Box>

      <Divider
        sx={{
          mt: { xs: 2, sm: 3 },
          ml: { sm: 3.5 },
          width: { sm: "96%" },
        }}
      />
      {/* Content in fade cotnainer */}
      <Box
        sx={{
          px: { xs: 0, sm: 3, lg: 9 },
          pt: { xs: 0, md: 5 },
          pb: { xs: 0, md: 5 },
          height: "calc(100% - 76px)",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          background: "white",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContentContainer;
