import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import backgroundImg from "../assets/login_bg.jpg";
import logo from "../assets/Logo.svg";
import LoginForm from "../Components/LoginForm";
import AuthContext from "../contexts/AuthContext";

const AdminLogIn = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await auth.authHandler();
      auth.isAuth && navigate("/kunder");
    }
    fetchData();
  });

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `linear-gradient(180deg, rgba(245, 246, 252, 0.4) 40%, rgba(32,108,94,0.8) 100%), url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* LOGO */}
      <Box
        sx={{
          position: "absolute",
          top: 50,
          left: { xs: "50%", sm: 50 },
          transform: { xs: "translateX(-50%)", sm: "none" },
        }}
      >
        <img src={logo} alt="" width="150px" />
      </Box>

      {/* FORM */}
      <Container maxWidth="xs">
        <LoginForm />
      </Container>
    </Box>
  );
};

export default AdminLogIn;
